import { useState } from "react";
import { getImageUrl } from "src/Common/common";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

function DropDown({ index, onDeleteClicked, onEditClicked }) {
  const [optionShown, setOptionShown] = useState(false);

  return (
    <div className="relative">
      <img
        onClick={() => setOptionShown(!optionShown)}
        className="cursor-pointer"
        src={getImageUrl("/images/common/options.png")}
      />
      {optionShown && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setOptionShown(false);
          }}
        >
          <div className="flex flex-col items-center py-1 justify-between absolute top-[25px] left-[-5px] w-[28px] h-[54px] border-[1px] rounded-[6px] border-borderColor bg-white z-10 shadow-md">
            <img
              onClick={() => {
                onEditClicked(index);
                setOptionShown(false);
              }}
              className="w-[14px] cursor-pointer"
              src={getImageUrl("/images/common/edit.png")}
            />
            <img
              onClick={() => {
                onDeleteClicked(index);
                setOptionShown(false);
              }}
              className=" w-[14px] cursor-pointer"
              src={getImageUrl("/images/common/black_trash.png")}
            />
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
}

export default DropDown;
