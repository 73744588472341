import api from "./api";
import UserService from "./user.service";
import { isAdmin } from "src/Common/function";

const create = (data, branch) => {
  return api.post("/api/invite/create", data);
};

const accept = (data) => {
  return api.get("/api/invite/accept", { params: data });
};

const getAll = (branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.get("/api/invite/get-all", { params: { branchId } });
};

const getPendingAll = () => {
  return api.get("/api/invite/get-pending-all");
};

const deleteInvite = (id) => {
  return api.get("/api/invite/delete", { params: { id } });
};

const InviteService = {
  create,
  accept,
  getAll,
  getPendingAll,
  deleteInvite,
};

export default InviteService;
