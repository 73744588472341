import { current } from "@reduxjs/toolkit";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import StatusDropdown from "./components/statusdropdown";
import UserSelectModal from "../Notification/components/userselectmodal";
import UserService from "src/Services/API/user.service";
import { handleError, isAdmin } from "src/Common/function";
import { formatTime } from "src/Common/common";
import NotificationService from "src/Services/API/notification.service";
import Technicians from "./components/technicians";
import DayOfWeekDropdown from "./components/dayofweekdropdown";
import TimePickerModal from "src/Common/timepickermodal";
import DayDropdown from "./components/daydropdown";
import OpenWorkOrders from "./openworkorders";
import HoursWorked from "./hoursworked";
import BranchBar from "src/Components/branchbar/BranchBar";
import ReportService from "src/Services/API/report.service";
import dayjs from "dayjs";

const ReportCenter = () => {
  const branch = useSelector((state) => state.Branch.branch);
  const [userArray, setUserArray] = useState([]);
  const [openWORecipientIdList, setOpenWORecipientIdList] = useState([]);
  const [hoursWorkedRecipientIdList, setHoursWorkedRecipientIdList] = useState([]);

  useEffect(() => {
    setOpenWORecipientIdList([]);
    setHoursWorkedRecipientIdList([]);
    UserService.getAllUsers(branch)
      .then((response) => {
        if (response.status == 200) {
          setUserArray(response.data);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, [branch]);

  const sendOpenWOReport = (past7Days, past30Days) => {
    ReportService.sendOpenWOReport(branch, openWORecipientIdList, past7Days, past30Days, new Date())
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <div className="w-full">
      <div className="px-7 py-2">{isAdmin() && <BranchBar allEnabled={false} />}</div>
      <OpenWorkOrders
        userArray={userArray}
        recipientIdList={openWORecipientIdList}
        setRecipientIdList={setOpenWORecipientIdList}
        sendReport={sendOpenWOReport}
      />
      <div className="h-3" />
      <HoursWorked
        userArray={userArray}
        recipientIdList={hoursWorkedRecipientIdList}
        setRecipientIdList={setHoursWorkedRecipientIdList}
      />
    </div>
  );
};

export default ReportCenter;
