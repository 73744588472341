import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/Common/common";

function RoleDropDown({ roles, roleClicked }) {
  const [isDropDownShown, setIsDropDownShown] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const roleSelected = (role) => {
    setSelectedRole(role);
    roleClicked(role);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropDownShown(false);
      }}
    >
      <div
        onClick={() => setIsDropDownShown(!isDropDownShown)}
        className="w-[310px] px-[15px] py-[5px]  col-span-1 flex items-center justify-between cursor-pointer relative"
      >
        <span className="font-OpenSansRegular text-[10px] font-[400]">
          {selectedRole ? selectedRole.name : "Select role"}
        </span>
        <img src={getImageUrl("/images/common/arrow-down.png")} />

        {isDropDownShown && (
          <div className="w-full absolute  px-1 py-1 max-h-[140px] overflow-auto border-[#E6E7EC] border-[1px]  top-[39px] left-0 z-10 bg-white flex flex-col justify-between shadow-md">
            {roles.map((role, index) => (
              <div
                onClick={() => roleSelected(role)}
                key={index}
                className="w-full h-[20px] flex items-center gap-2 px-2 hover:bg-slate-400"
              >
                <p className="font-OpenSansRegular text-[10px] text-inputEmailTitle font-[400]">{role.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}

export default RoleDropDown;
