import { current } from "@reduxjs/toolkit";
import { isAdmin } from "src/Common/function";
import api from "./api";
import UserService from "./user.service";

//This function is for admin
const sendOpenWOReport = (branch, recipients, past7Days, past30Days, date) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.get("/api/wo/send_open_wo_report", { params: { branchId, recipients, past7Days, past30Days, date } });
};

const ReportService = {
  sendOpenWOReport,
};

export default ReportService;
