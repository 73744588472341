import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { getImageUrl } from "src/Common/common";

function BranchDropDown({ user, branches, onChange }) {
  const [isDropDownShown, setIsDropDownShown] = useState(false);

  useEffect(() => {
    console.log("🚀 ~ BranchDropDown ~ branches:", branches);
  }, [isDropDownShown]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        console.log("**********");
        setIsDropDownShown(false);
      }}
    >
      <div
        onClick={() => {
          console.log("-------------");
          setIsDropDownShown(true);
        }}
        className="w-[240px] px-[15px] py-[5px]  col-span-1 flex items-center justify-between cursor-pointer "
      >
        <span className="font-OpenSansRegular text-[10px] font-[400]">
          {user.branches.map((branch) => branch.name).join(", ")}
        </span>
        <img src={getImageUrl("/images/common/arrow-down.png")} />

        {isDropDownShown && (
          <div className="w-full absolute max-h-[250px] overflow-y-auto px-1 py-1 border-[#E6E7EC] border-[1px]  top-[39px] left-0 z-10 bg-white flex flex-col justify-between shadow-md">
            {branches.map((branch, index) => {
              return (
                <div key={index} className="w-full h-[30px] flex items-center gap-2 px-2 ">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={user.branches.map((b) => b.id).includes(branch.id)}
                      onChange={(event) => onChange(user, branch, event.target.checked)}
                    />
                    <p className="font-OpenSansRegular text-[11px] text-inputEmailTitle font-[400]">{branch.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}

export default BranchDropDown;
