import { useEffect, useState } from "react";
import { limitString } from "src/Common/function";

const Technicians = ({ recipients, onClick }) => {
  return (
    <div className="w-full flex items-center justify-between gap-4">
      <p className="font-OpenSansMedium text-[13px] text-black ">Send Report To:</p>
      <div
        onClick={onClick}
        className="flex-auto h-[30px] border-[1px] rounded-md border-gray-200 cursor-pointer flex items-center px-2"
      >
        <p className="font-OpenSansMedium text-[13px] text-black">{limitString(recipients, 48)}</p>
      </div>
    </div>
  );
};

export default Technicians;
