import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import UserService from "src/Services/API/user.service";
import DropDown from "../../Manage/roledropdown";
import RoleDropDown from "./roledropdown";
import BranchDropDown from "./branchdropdown";
import { useSelector } from "react-redux";

function InviteModal({ shown, roleArray, branchArray, onCancelClicked, sendInvite }) {
  const selectedBranch = useSelector((state) => state.Branch.branch);
  const currentUser = UserService.getCurrentUser();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);
  const [branch, setBranch] = useState(null);

  useEffect(() => {
    setEmail("");
  }, [shown]);

  useEffect(() => {
    setBranch(selectedBranch);
  }, [selectedBranch]);

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center">
        <OutsideClickHandler onOutsideClick={onCancelClicked}>
          <div onClick={(e) => e.stopPropagation()} className="w-[350px] bg-white rounded-[6px] p-[18px] flex flex-col">
            <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">INVITE USER</p>

            <div className="flex flex-col justify-between h-full">
              <div className="h-[60px] w-full">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Email</p>
                <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
                  <input
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                    placeholder="Enter email"
                  />
                </div>
              </div>

              <div className="h-[60px] w-full mt-4">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Role</p>
                <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
                  <RoleDropDown roles={roleArray} roleClicked={(value) => setRole(value)} />
                </div>
              </div>

              {selectedBranch === 0 && (
                <div className="h-[60px] w-full mt-4">
                  <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Branch</p>
                  <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
                    <BranchDropDown branches={branchArray} branchClicked={(value) => setBranch(value)} />
                  </div>
                </div>
              )}

              <div className="w-full mt-5">
                <div className="flex items-center justify-between gap-4">
                  <div
                    onClick={onCancelClicked}
                    className="w-[50%] h-[30px] text-[10px] rounded-[6px] border-[1px] border-subTitleColor flex items-center justify-center text-subTitleColor cursor-pointer"
                  >
                    CANCEL
                  </div>
                  <div
                    onClick={() => {
                      if (email.length > 0 && role && branch) sendInvite(email, role, branch);
                    }}
                    className="w-[50%] h-[30px] text-[10px] rounded-[6px] flex items-center justify-center bg-blueColor text-white cursor-pointer"
                  >
                    SEND INVITE
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
}

export default InviteModal;
