import { useEffect, useState, forwardRef } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";

import { isAdmin } from "src/Common/function";
import CustomerDropDown from "./dropdown/customerdropdown";
import CustomerService from "src/Services/API/customer.service";
import StatusDropDown from "./dropdown/statusdropdown";
import { WO_TYPE_LIST } from "src/Common/contants";
import UserService from "src/Services/API/user.service";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import WOStatusDropDown from "./dropdown/wostatusdropdown";
import DatePickerModal from "src/Common/datepickermodal";
import { toLocalDate } from "src/Common/common";
import DateInput from "src/Components/Input/dateinput";

function CreateModal({ order, shown, onCancelClicked, onSubmitClicked }) {
  const currentUser = UserService.getCurrentUser();
  const branch = useSelector((state) => state.Branch.branch);
  const [woNumber, setWoNumber] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [type, setType] = useState(0);
  const [nte, setNte] = useState(100);
  const [startDate, setStartDate] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [asset, setAsset] = useState("");
  const [customerPONumber, setCustomerPONumber] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(-1);

  const StartInput = forwardRef(({ value, onClick }, ref) => <DateInput text={value} onClick={onClick} />);

  useEffect(() => {
    getCustomers();
  }, [shown]);

  useEffect(() => {
    console.log("EEEEEEEE: ", order);
    if (order) {
      setWoNumber(order.number);
      setDescription(order.description);
      setAsset(order.asset);
      setCustomerPONumber(order.customerPONumber);
      setCustomer(order.customer);
      setCustomerName(order.customer.company);
      setNte(order.NTE);
      setStartDate(order.startDate);
      setType(order.type);
      setStatus(order.status);
      if (order.startDate) setStartDate(order.startDate);
    } else {
      setWoNumber("");
      setDescription("");
      setAsset("");
      setCustomerPONumber("");
      setCustomer(null);
      setCustomerName(null);
      setNte(100);
      setType(0);
      setStatus(0);
      setStartDate(null);
    }
  }, [shown]);

  const getCustomers = async () => {
    try {
      const response = await CustomerService.getAll(branch);
      console.log("customers response: ", response);
      setCustomers(response.data);
      setCustomerNames(response.data.map((item) => item.company));
    } catch (error) {
      console.log("customer fetching error: ", error);
    }
  };

  const customerSelected = (index) => {
    console.log("customers[index]: ", customers[index]);
    setCustomer(customers[index]);
    setCustomerName(customers[index].company);
  };

  const typeSelected = (index) => {
    setType(index);
  };

  const submitClicked = () => {
    if (!customer) return;

    let data = {};
    if (order) {
      if (order.number !== woNumber) data["number"] = woNumber;
      if (order.customer.id !== customer.id) data["customer"] = customer;
      if (order.asset !== asset) data["asset"] = asset;
      if (order.customerPONumber !== customerPONumber) data["customerPONumber"] = customerPONumber;
      if (order.type !== type) data["type"] = type;
      if (order.NTE !== nte) data["NTE"] = nte;
      if (order.description !== description) data["description"] = description;
      if (order.status !== status) data["status"] = status;
      if (!moment(order.startDate).isSame(moment(startDate))) data["startDate"] = startDate;
    } else {
      let branchId = branch;
      if (!isAdmin()) {
        branchId = currentUser.branches[0].id;
      }
      data = {
        requestedDate: new Date(),
        requestedUser: UserService.getCurrentUser(),
        number: woNumber,
        customer: customer,
        asset: asset,
        customerPONumber: customerPONumber,
        status: 0, //requested
        type: type,
        NTE: nte,
        description: description,
        startDate: startDate,
        branch: branchId,
      };
    }

    console.log("data: ", data);

    onSubmitClicked(data);
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-30 flex items-center justify-center z-30">
        {datePickerOpen && (
          <DatePickerModal
            shown={datePickerOpen}
            pSelectedDate={startDate}
            cancelClicked={() => setDatePickerOpen(false)}
            dateSelected={(date) => {
              setStartDate(date);
              setDatePickerOpen(false);
            }}
          />
        )}

        <OutsideClickHandler onOutsideClick={() => onCancelClicked()}>
          <div
            // onClick={modalClicked}
            className="w-[436px] h-[700px] bg-white rounded-[6px] p-[18px] flex flex-col relative"
          >
            {currentUser.roles[0].id <= 2 && order && (
              <div className="absolute top-2 right-2 flex items-center gap-2">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor">Status: </p>
                <div className="w-[120px] ">
                  <WOStatusDropDown defaultOption={status} onSelect={(value) => setStatus(value)} />
                </div>
              </div>
            )}
            <p className="text-[16px] font-OpenSansMedium font-[600] text-titleColor mb-5">
              {order ? "EDIT WORK ORDER" : "CREATE WORK ORDER"}
            </p>
            <div className=" w-full">
              <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Work Order #</p>
              <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
                <input
                  value={woNumber}
                  type="text"
                  onChange={(e) => setWoNumber(e.target.value.trim())}
                  className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                  placeholder="Enter WO #"
                />
              </div>
            </div>

            <div className="flex flex-col justify-between h-full mt-4">
              <div className="h-[60px] w-full mb-2">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Customer</p>
                {/* <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]"> */}
                <CustomerDropDown options={customerNames} defaultOption={customerName} onSelect={customerSelected} />
                {/* </div> */}
              </div>

              <div className="h-[60px] w-full mb-2">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-2">Type of Service</p>
                <StatusDropDown defaultOption={WO_TYPE_LIST[type]} onSelect={typeSelected} />
              </div>

              <div className="h-[60px] w-full mb-2">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-1">NTE</p>
                <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
                  <input
                    value={nte}
                    type="number"
                    onChange={(e) => setNte(e.target.value)}
                    className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                    placeholder="Enter NTE"
                  />
                </div>
              </div>

              <div className="h-[60px] w-full mb-2">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-1">Start Date</p>

                {/* <div
                  onClick={() => setDatePickerOpen(true)}
                  className="w-full h-[30px] border-borderColor border-[1px] rounded-[6px] flex items-center px-[15px] gap-1 text-inputEmailTitle text-[11px] cursor-pointer"
                > */}
                {/* {startDate ? startDate : "Select date"} */}
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(moment(date).startOf("day").format("YYYY-MM-DD"));
                  }}
                  customInput={<StartInput />}
                />
              </div>

              <div className="w-full mb-2">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-1">Asset</p>
                <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
                  <input
                    value={asset}
                    type="text"
                    onChange={(e) => setAsset(e.target.value)}
                    className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                    placeholder="Enter Asset"
                  />
                </div>
              </div>

              <div className="w-full mb-2">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-1">
                  Customer Purchase Order #
                </p>
                <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]">
                  <input
                    value={customerPONumber}
                    type="text"
                    onChange={(e) => setCustomerPONumber(e.target.value)}
                    className="w-full text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium"
                    placeholder="Enter Customer Purchase Order Number"
                  />
                </div>
              </div>

              <div className="w-full mb-2">
                <p className="text-[12px] font-OpenSansMedium font-[600] text-titleColor mb-1">Description</p>
                {/* <div className="w-full h-[30px] border-solid border-[1px] rounded-[5px] flex items-center justify-center px-[15px]"> */}
                <textarea
                  value={description}
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                  multiple={true}
                  className="w-full h-[70px] order-solid border-[1px] rounded-[5px] text-inputEmailTitle text-[11px] font-[400] font-OpenSansMedium px-2 py-1"
                  placeholder="Enter Description"
                />
                {/* </div> */}
              </div>

              <div className="w-full mt-2">
                <div className="flex items-center justify-between gap-4">
                  <div
                    onClick={onCancelClicked}
                    className="w-[50%] h-[30px] text-[10px] rounded-[6px] border-[1px] border-subTitleColor flex items-center justify-center text-subTitleColor cursor-pointer"
                  >
                    CANCEL
                  </div>
                  <div
                    onClick={submitClicked}
                    className="w-[50%] h-[30px] text-[10px] rounded-[6px] flex items-center justify-center bg-blueColor text-white cursor-pointer"
                  >
                    SUBMIT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
}

export default CreateModal;
