import { current } from "@reduxjs/toolkit";
import api from "./api";
import UserService from "./user.service";
import { isAdmin } from "src/Common/function";

const create = (type, flow, event, recipientList, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  console.log("AAAAAAAAAAAAAAAAAAA: ", branchId);
  return api.post("/api/notification/create", { type, flow, event, recipientList, branch: branchId });
};

// const update = (id, recipientList) => {
//   return api.post("/api/notification/update", { id, recipientList });
// };

const get = (type, flow, event, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.post("/api/notification/get", { type, flow, event, branchId });
};

const NotificationService = {
  create,
  get,
};

export default NotificationService;
