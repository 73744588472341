import api from "./api";
import UserService from "./user.service";
import { isAdmin } from "src/Common/function";

const getPurchaseOrder = (userId, keyword, pageNumber, criteria, sort, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.get("/api/po/get", {
    params: { userId, branchId, keyword, pageNumber, criteria, sort },
  });
};

const getPurchaseOrdersByWoId = (woId) => {
  return api.get("/api/po/get_all", { params: { woId: woId } });
};

const getPurchaseOrdersByPoId = (poId) => {
  return api.get("/api/po/getOrderById", { params: { poId: poId } });
};

const savePurchaseOrder = (data) => {
  return api.post("/api/po/create", data);
};

const update = (data) => {
  return api.post("/api/po/update", data);
};

const uploadFile = (file, orderId) => {
  const formData = new FormData();
  formData.append("id", orderId);
  formData.append("file", file);

  return api.post("/api/po/attach", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

const attachFile = (data) => {
  return api.post("/api/po/upload_attachment", data);
};

// const changeStatusWorkOrder = (data) => {
//   return api.post("/api/wo/change_status", data);
// };

// const assignTechs = (data) => {
//   return api.post("/api/wo/assign_tech", data);
// };

const POService = {
  savePurchaseOrder,
  getPurchaseOrdersByWoId,
  uploadFile,
  update,
  getPurchaseOrder,
  getPurchaseOrdersByPoId,
  attachFile,
};

export default POService;
