import { isAdmin } from "src/Common/function";
import api from "./api";
import UserService from "./user.service";

const getAllWorkOrders = (userId, keyword, pageNumber, criteria, sort, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.get("/api/wo/get_all", {
    params: { userId, branchId, keyword, pageNumber, criteria, sort },
  });
};

const getAllOrderNumber = (branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  console.log("🚀 ~ getAllOrderNumber ~ branchId:", branchId);
  return api.get("/api/wo/getAllOrderNumber", { params: { branchId } });
};

const getAllRecommendations = (keyword, pageNumber, branch) => {
  let currentUser = UserService.getCurrentUser();
  let branchId = branch;
  if (!isAdmin()) {
    branchId = currentUser.branches[0].id;
  }
  return api.get("/api/wo/get_recommendations", { params: { keyword, pageNumber, branchId } });
};

const createWorkOrder = (data) => {
  return api.post("/api/wo/create_wo", data);
};

const getWorkOrderById = (id) => {
  return api.get("/api/wo/getById", { params: { id } });
};

const getWorkOrderByNumber = (number) => {
  return api.get("/api/wo/getByNumber", { params: { number } });
};

const update = (data) => {
  return api.post("/api/wo/update", data);
};

const uploadFiles = (files, orderId) => {
  const formData = new FormData();
  formData.append("id", orderId);
  for (let file of files) {
    formData.append("files", file);
  }

  return api.post("/api/wo/attach", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

const attachMaterials = (data) => {
  return api.post("/api/wo/attach_materials", data);
};

const updateMaterial = (woID, index, data, eventUser) => {
  return api.post("/api/wo/update_material", { woID, index, data, eventUser });
};

const previewServiceTicket = (woId) => {
  return api.get("/api/wo/preview_serviceticket", { params: { id: woId } });
};

const sendServiceTicket = (woId) => {
  return api.get("/api/wo/send_serviceticket", {
    params: { id: woId, stSentDate: new Date() },
  });
};

const deleteById = (id) => {
  return api.get("/api/wo/deleteByID", { params: { id } });
};

const WOService = {
  createWorkOrder,
  getAllWorkOrders,
  sendServiceTicket,
  previewServiceTicket,
  attachMaterials,
  getWorkOrderById,
  getWorkOrderByNumber,
  uploadFiles,
  update,
  deleteById,
  updateMaterial,
  getAllRecommendations,
  getAllOrderNumber,
};

export default WOService;
